import type { KBItem } from '~/ts/types/knowledge-base'
import { KBItemTypeEnum } from '~/ts/enums/knowledge-base'

export default (items: KBItem[], search: string): any => {
    if (!search) {
        return items
    }

    const handler = (result, item) => {
        if (String(item.title).toLowerCase().includes(search.toLowerCase())) {
            result.push(item)

            return result
        }

        if (item.type === KBItemTypeEnum.Category) {
            const children = item.children.reduce(handler, [])

            if (children.length) {
                result.push({ ...item, children })
            }
        }

        return result
    }

    return items.reduce(handler, [])
}
