import type { KBItem } from '~/ts/types/knowledge-base'

export default (items: KBItem[]): any => {
    const handler = (children, items) => {
        children.forEach((node) => {
            const { children, ...rest } = node

            items.push(rest)

            if (children) {
                handler(children, items)
            }
        })

        return items
    }

    return handler(items, [])
}
